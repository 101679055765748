

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.text-dark-blue {
  color: #202A44;
}

.text-blue {
  color: #002F6C;
}

.text-red {
  color: #BD162C;
}

.bg-dark-blue {
  background-color: #202A44;
}

.bg-blue {
  background-color: #002F6C;
}

.bg-red {
  background-color: #BD162C;
}

.banner {
  background-image: url('/public/banner-bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  
}

.cityResult {
  position: absolute;
  top: 70px;
  left: calc(100% - 315px);
  width: 300px;
  height: auto;
  background-color: white;
  padding: 20px;
  border-radius: 20px;
  opacity: .9;
}

.mapFrame {
  width: 100%;
  height: 100vh;
  padding-left: 5px;
}

.map-container {
  height: 400px;
}

@media screen and (max-width: 767px) {
  .cityResult {
    position: relative;
    width: 100%;
    left: 0px;
    height: auto;
    background-color: white;
    padding: 20px;
    border-radius: 0px;
    opacity: 1;
  }
}

.cta-image {
  background-image: url('../public/assets/img/pexels-6238683.jpg');
  background-size: cover;
  background-position: center;
}
.cta-image-o {
  background: linear-gradient(to right, #012f6c, transparent);
}